import React, { Component } from "react";
import { Box, Spinner, Flex, Text, Stack, Tag, Avatar, Divider, Icon, Skeleton, List, ListItem, useToast, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton } from "@chakra-ui/core";
import { Route } from 'react-router-dom';
import actions from './actions';
import { FaFacebookSquare } from "react-icons/fa"
import { FaTwitterSquare } from "react-icons/fa"
import './App.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import FeedItem from './FeedItem';

function Legislator({ item = {}, ...rest }) {
  const { urls = [], name, title, chamber, avatar_url, subtitle } = item;
  return (
  <Box key={item.title} p={5} {...rest}>
    <Stack align="center">
      <Flex>
        <Avatar name={name} src={avatar_url} />
      </Flex>
      <Flex>
        <Stack textAlign="center" align="center">
          <Text fontSize="md" fontWeight="bold">{title}</Text>
          <Tag fontWeight="bold"
               textTransform="uppercase"
               fontSize="0.6em"
               letterSpacing="wide"
               color="purple.700">{subtitle}</Tag>
          {/*<Tag fontSize="sm" color="purple.700">{chamber}</Tag>*/}
        </Stack>
      </Flex>
      {urls.length > 0 && (
        <Stack isInline>
        {urls.map(url => (
          <a key={url.link} href={url.link} target="_blank" rel="noopener noreferrer">
            {url.type === "facebook" && <Box as={FaFacebookSquare} size="1.5em" color="purple.700"/>}
            {url.type === "twitter" && <Box as={FaTwitterSquare} size="1.5em" color="purple.700"/>}
          </a>
        ))}
        </Stack>
      )}
        
    </Stack>
  </Box>)
}

function Action({ item = {}, ...rest }) {
  const { avatar_url, title, subtitle, date, name, tags = [], key, bill_url, type, id } = item;

  return (
      <Box key={key} p={5} {...rest}>
        <Stack alignItems="top" isInline>
          <Box flexShrink="0">
            <Avatar name={name} src={avatar_url} />
          </Box>
          <Stack>
            <Stack isInline alignItems="center">
              <Text
                display="block"
                fontSize="md"
                lineHeight="normal"
                fontWeight="bold"
              >
                {title}
              </Text>            
              <Text fontSize="xs" color="gray.500" d={["none", null,"inline"]}>
                {`${date}`}
              </Text>
            </Stack>
            <Text
              display="block"
              fontWeight="normal"
              fontSize="sm"
              color="gray.800"
              lineHeight="tall"
              >
              {subtitle}
            </Text>
          </Stack>
        </Stack>
        <Flex wrap="wrap">
          {tags.map(tag => { return <a key={tag.title} href={tag.url} target="_blank" rel="noopener noreferrer"><Tag
          id={tag.url}
          fontWeight="bold"
          textTransform="uppercase"
          fontSize="0.6em"
          letterSpacing="wide"
          color="purple.700"
          mr={2}
          mt={2}
          style={{cursor: tag.url ? "pointer" : "inherit" }}
        >
          {tag.url ?
              <Stack id={tag.url} isInline align="center" spacing={2}><Text id={tag.url}>{tag.title}</Text>{tag.url ? <Icon id={tag.url} name="external-link" /> : <div></div>}</Stack>
            :
              <Text>{tag.title}</Text>
          }
        </Tag></a>})}
        </Flex>
        <Text mt={2} fontSize="xs" color="gray.500" d={["block",null,"none"]}>
          {`${date}`}
        </Text>
      </Box>
  );
}

function withHook(Component) {
  return function WrappedComponent(props) {
    const toast = useToast();
    return <Component {...props} toast={toast} />;
  }
}

class Feed extends Component {
  constructor(props) {
    super(props);

    this.state = { feed: { items: [] }, legislators: { items: [] }, nextToken: false};
  }

  componentWillMount() {
    const { onHeaderStateChange } = this.props;
    onHeaderStateChange();
    this.fetchData();
  }

  fetchData = async () => {
    const { feed, isFetching, nextToken } = this.state;
    if(isFetching) return;

    this.setState({ isFetching: true });
    const { res = {}, err } = await actions.fetchFeed(nextToken);
    if(err) actions.showErrorToast(this.props.toast, err.message);
    else {
      const { feed: feedItem = {}, legislators: legislatorsItem = {}, signupState } = res;

      if(signupState) {
        this.props.history.push(signupState);
      } else {
        const { items, status, token, nextToken: _nextToken, ...rest } = feedItem;
        feed.status = status;
        if(status === "building" && token) actions.initiateWebsocketConnection(token, this.wsCallback);
        else if(!status || status === "complete") {
          feed.items = feed.items.concat(items);
          //setNextToken(_nextToken || false);
          //setFeed(feed);
          this.setState({ feed, nextToken: _nextToken || false });
        }
        
        this.setState({ legislators: legislatorsItem });
      }
    }


    this.setState({ isFetching: false });
  }

  wsCallback = (data = {}) => {
    const { action } = data;
    if(action === "refresh") this.fetchData();
  }

  renderSkeleton = (length = 0) => {
    return (
      <div>
        {[...Array(length)].map((_,i) => <Skeleton key={i} h="30px" my="10px" m={5}/>)}
      </div>
    )
  }

  renderMessage = (message, hideSpinner) => {
    return (
      <Stack mt={message ? 10 : 5} mb={message ? 10 : 5} align="center">
        {!hideSpinner && <Spinner />}
        <Text fontWeight="bold">{message}</Text>
      </Stack>
    )
  }

  triggerModal = (id) => {
    this.setState({ modalId: id });
    this.props.history.push(`${this.props.match.url}/${id}`)
  }

  onModalClose = () => {
    const { match: { url } } = this.props;
    this.props.history.push(url);
  }

  render() {
    const { feed, legislators, nextToken, isFetching, modalId } = this.state;
    const { items: feedItems = [], status } = feed;
    const { items: legislatorItems = [] } = legislators;

    const { match: { params, url }, location: { pathname }, toast } = this.props;
    var myRe = /\/home\/[A-Za-z0-9-]\S*/gi;

    return (
      <div>
        <Route
          path={`/home/:feedId`}
          render={(props) => { return billModal({ isOpen: myRe.test(pathname), onClose: this.onModalClose,  ...props }) }}
        />
        <Stack p={[1,1,5]} isInline spacing={[0,0,2]}>
        <Stack w={['100%','100%','50%']} ml={[0,0,'15%']} >
          <Text d={["none","none","inherit"]} mt={3} fontSize="md" fontWeight="medium">Your Feed</Text>
          <Box backgroundColor="white" borderWidth="1px" rounded="lg" borderRadius="2px" boxShadow="0 1px 1px 0 rgba(0,0,0,0.05)">
            {status === "building" ? 
              this.renderMessage("Building your feed...")
            : <List spacing={0}>
              {isFetching && this.renderSkeleton(5) }
              {(!isFetching && feedItems.length === 0) && this.renderMessage("There's nothing to display yet in your feed 🤷‍♂️", true)}
              <InfiniteScroll
                dataLength={feedItems.length}
                next={this.fetchData}
                hasMore={nextToken}
                loader={this.renderMessage()}
                scrollThreshold={0.8}>
                {feedItems.map(item => (<ListItem key={item.key} id={item.key} className="cell" onClick={() => this.triggerModal(item.id)}><Action item={item} /><Divider m={0} d={feedItems.indexOf(item) === feedItems.length - 1 && "none"} /></ListItem>))}
              </InfiniteScroll>
            </List>}
          </Box>
        </Stack>
        <Stack d={["none","none","inherit"]} mr={[0,0,'15%']} w={['20%']}>
          <Text mt={3} fontSize="md" fontWeight="medium">Your Legislators</Text>
          <Box borderWidth="1px" backgroundColor="white" rounded="lg" borderRadius="2px" boxShadow="0 1px 1px 0 rgba(0,0,0,0.05)">
            <List>
              {legislatorItems.length === 0 && this.renderSkeleton(3)}
              {legislatorItems.map(item => (<ListItem key={item.title}><Legislator item={item} /><Divider d={legislatorItems.indexOf(item) === legislatorItems.length - 1 && "none"} /></ListItem>))}
            </List>
          </Box>
          <Divider />
          <Box textAlign="center">
            <Stack>
              <Stack justify="center">
                <Text fontWeight="medium" color="gray.800" fontSize="xs"><a href="https://projectcongress.com/tos" target="_blank" rel="noopener noreferrer">Terms Of Service</a></Text>
                <Text fontWeight="medium" color="gray.800" fontSize="xs"><a href="https://projectcongress.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></Text>
                <Text textAlign="center" color="gray.500" fontSize="xs">© 2020 <a href="https://barrickapps.com?ref=pc" target="_blank" rel="noopener noreferrer">Barrick Apps LLC</a></Text>
                <Text textAlign="center" color="gray.400" fontSize="xs">v1.0.5</Text>
              </Stack>
            </Stack>
          </Box>
          
        </Stack>
      </Stack>
      </div>
    );
  }
}

function billModal(props) {
  const { isOpen, onClose } = props;
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose} >
      <ModalOverlay />
      <ModalContent borderRadius="2px" pb={4}>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FeedItem {...props} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default withHook(Feed);