import React, { useState, useEffect } from 'react';
import '../App.css';
import { 
  Image,
  Box,
  useToast } from "@chakra-ui/core";
import Email from './Email';
import Password from './Password';

import actions from '../actions';
import { Route, Switch, useRouteMatch, useHistory, useLocation, Redirect } from 'react-router-dom';

function ForgotPassword({ login, onAuthStateChange, ...rest }) {
  const [form, updateForm] = useState({});
  const [interests, setInterests] = useState([]);

  let match = useRouteMatch();
  let history = useHistory();
  let { pathname } = useLocation();
  const toast = useToast();

  async function onNext(data = {}) {
    for(const key in data) {
        form[key] = data[key];
        updateForm(form);
    }
    const { email } = form;
    const { password, code } = data;
    switch(pathname) {
        case "/forgot/email": {
            const { err } = await actions.forgotPassword(email);
            if(err) actions.showErrorToast(toast, err.message);
            else history.push("/forgot/password");
        } break;
        case "/forgot/password": {
            const { err } = await actions.inputForgotPasswordCode(email, password, code);
            if(err) actions.showErrorToast(toast, err.message);
            else {
                actions.showErrorToast(toast, "Password reset. Please log in with your new password.", "success");
                history.push("/login");
            }
        } break;
    }
  }

  return (
      <Box p={[3,3,10]} m={{sm: 0, md:"3em auto 0 auto"}} borderWidth="1px" backgroundColor="white" maxW="500px" >
        {/*<Image src="https://assets.projectcongress.com/logo.png" w="10em" />*/}
        <Switch>
            <Route path={`${match.path}/email`} render={(props) => <Email onNext={onNext} {...props} />} />
            <Route path={`${match.path}/password`} render={(props) => <Password onNext={onNext} {...props} />} />
            <Redirect to={`/forgot/email`} />
        </Switch>
      </Box>
    
  );
}

export default ForgotPassword;
