import React from 'react';
import { useForm } from "react-hook-form";
import '../App.css';
import { FormErrorMessage,
  FormControl,
  Input,
  Button,
  Text,
  Flex,
  Box } from "@chakra-ui/core";
import { Link } from 'react-router-dom';

function CreateProfile({ onNext, mode, ...rest }) {
  const { handleSubmit, errors, register, formState } = useForm();

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Email is required";
    }
    return error || true;
  }

  function validatePassword(value) {
    let error;
    if (!value) {
      error = "Password is required";
    } else if(value.length < 8) {
      error = "Password must be at least 8 characters";
    }
    return error || true;
  }

  function validateName(value) {
    let error;
    if (!value) {
      error = "Name is required";
    }
    return error || true;
  }

  return (
    <Box pb={5} w="100%" {...rest} textAlign="center">
      <form onSubmit={handleSubmit(onNext)}>
        <FormControl mt={5} isInvalid={errors.name}>
          {/*<FormLabel htmlFor="name">Zip Code</FormLabel>*/}
          <Text fontWeight="bold">First Name</Text>
          <Input
            name="name"
            placeholder="First Name"
            autoFocus={true}
            borderRadius="2px"
            height="70px"
            textAlign="center"
            fontSize="lg"
            ref={register({ validate: validateName })}
          />
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl mt={10} isInvalid={errors.email}>
          {/*<FormLabel htmlFor="name">Email</FormLabel>*/}
          <Text fontWeight="bold">Email</Text>
          <Input
            name="email"
            placeholder="Email"
            borderRadius="2px"
            height="70px"
            textAlign="center"
            fontSize="lg"
            type="email"
            ref={register({ validate: validateEmail })}
          />
          <Text mt={1} fontSize="xs" color="gray.500">You will use this to sign in and view your feed</Text>
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl mt={10} isInvalid={errors.password}>
          {/*<FormLabel htmlFor="name">Password</FormLabel>*/}
          <Text fontWeight="bold">Password</Text>
          <Input
            name="password"
            placeholder="Password"
            type="password"
            borderRadius="2px"
            height="70px"
            textAlign="center"
            fontSize="lg"
            ref={register({ validate: validatePassword })}
          />
          <FormErrorMessage textAlign="center">
            {errors.password && errors.password.message}
          </FormErrorMessage>
          <Text mt={1} fontSize="xs" color="gray.500">At least 8 characters</Text>
        </FormControl>
        <Button
          mt={10}
          variantColor="purple"
          isLoading={formState.isSubmitting}
          type="submit"
          h="50px"
          borderRadius="2px"
          w="100%"
          fontSize="lg"
          fontWeight="bold"
          style={{textShadow:"0 -1px 0 rgba(0, 0, 0, .35)"}}
          onClick={handleSubmit(onNext)}
        >
            Create Profile
        </Button>
      </form>
      <Flex justify="center" align="center" flexDir="column">
        <Box h="1px" w="75%" m={5} backgroundColor="gray.200" />
        <Text fontSize="md" fontWeight="medium">Already have a profile? <Link style={{textDecoration: "underline"}} to="/login">Sign In</Link></Text>
      </Flex>
    </Box>
  );
}

export default CreateProfile;
