import React, { Component } from "react";
import {  Box, 
          Spinner, 
          Flex, 
          Text, 
          Stack, 
          Avatar, 
          Divider, 
          Skeleton, 
          Tag,
          Icon,
          List, 
          ListItem, 
          useToast } from "@chakra-ui/core";
import actions from './actions';
import './App.css';
import ReactMarkdown from 'react-markdown';

function withHook(Component) {
  return function WrappedComponent(props) {
    const toast = useToast();
    return <Component {...props} toast={toast} />;
  }
}

class FeedItem extends Component {
  constructor(props) {
    super(props);

    this.state = { bill: {} };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { toast, match: { params: { feedId } } } = this.props;

    if(feedId) {
      const { res = {}, err } = await actions.fetchFeedItem(feedId);
      if(err) actions.showErrorToast(toast, err.message);
      else {
        const { bill = {} } = res;
        this.setState({ bill });
      }
    
    } else {

    }
  }

  renderSponsor(sponsor = {}, introduced_at) {
    //console.log(summary);
    const { avatar_url, name, state, title, party } = sponsor;
    return (<Box p={5} backgroundColor="gray.50" borderRadius="2px" borderWidth="1px">
      <Stack isInline align="center">
        <Flex>
          <Avatar name={name} src={avatar_url} />
        </Flex>
        <Flex>
          <Stack>
            <Flex align="center">
              <Text fontSize="md" fontWeight="medium">{name}</Text>
              <Text pl={2} pt={1} fontSize="xs">{`(${party} - ${state})`}</Text>
            </Flex>
            <Text color="gray.500" fontSize="sm">{`Introduced ${introduced_at}`}</Text>
          </Stack>
        </Flex>
    </Stack>
    </Box>)
  }

  renderSummary(summary) {
    return (<Box p={5} backgroundColor="gray.50" borderRadius="2px" borderWidth="1px">
      <ReactMarkdown className="summary" style={{textDecoration:"underline"}} source={summary} skipHtml={true} />
      <Text fontSize="sm" mt={4} textAlign="center" color="gray.500">Bill summaries are authored by the <a href="https://www.loc.gov/crsinfo/about/" target="_blank" rel="noopener noreferrer" style={{textDecoration:"underline"}}>CRS</a></Text>
    </Box>)
  }

  renderSkeleton = (length = 0) => {
    return (
      <div>
        {[...Array(length)].map((_,i) => <Skeleton key={i} h="30px" my="10px" m={5}/>)}
      </div>
    )
  }

  renderSpinner = (message) => {
    return (
      <Stack mt={message ? 10 : 5} mb={message ? 10 : 5} align="center">
        <Spinner />
        <Text fontWeight="bold">{message}</Text>
      </Stack>
    )
  }

  render() {
    const { bill: { summary, sponsor, title, short_title, introduced_at, tags, stageItems } } = this.state;
    
    return (
      <Box backgroundColor="white">
        <Stack>
          <Box textAlign="center">
            <Text fontSize="md" fontWeight="bold">{short_title || title}</Text>
            <Flex justify="center" wrap="wrap">
              {tags && tags.map(tag => { 
                return (<a key={tag.title} href={tag.url} target="_blank" rel="noopener noreferrer">
                          <Tag
                            id={tag.url}
                            fontWeight="bold"
                            textTransform="uppercase"
                            fontSize="0.6em"
                            letterSpacing="wide"
                            color="purple.700"
                            mr={2}
                            mt={2}
                            style={{cursor: tag.url ? "pointer" : "inherit" }}
                          >
                            {tag.url ?
                                <Stack id={tag.url} isInline align="center" spacing={2}><Text id={tag.url}>{tag.title}</Text>{tag.url ? <Icon id={tag.url} name="external-link" /> : <div></div>}</Stack>
                              :
                                <Text>{tag.title}</Text>
                            }
                          </Tag>
                        </a>)})}
            </Flex>
            {(stageItems && stageItems.length > 0) &&
            <Stack mt={3} spacing={"1px"} justify="center" isInline>
              {stageItems.map(item => {
                return (
                <Flex key={item.title} align="center" justify="center" className={item.className} p={2} backgroundColor={item.bgColor}>
                  <Text fontSize="xs" fontWeight="medium" color={item.textColor}>{item.title}</Text>
                </Flex>
                )
              })}
              
              {/*<Flex p={2} backgroundColor="purple.500">
                <Text fontSize="xs" fontWeight="medium" color="white">Passed House</Text>
              </Flex>  
              <Flex className="activeStage" p={2}>
                <Text fontSize="xs" fontWeight="medium" color="gray.600">Passed Senate</Text>
              </Flex>  
              <Flex className="lastStage" p={2} backgroundColor="purple.100">
                <Text fontSize="xs" fontWeight="medium" color="gray.600">Became Law</Text>
            </Flex>*/}  
            </Stack>}
            <Divider />
          </Box>
          <Box>
            <Text fontSize="sm" fontWeight="medium">Sponsor</Text>
            {sponsor ? this.renderSponsor(sponsor, introduced_at) : this.renderSkeleton(1)}
          </Box>
          <Box mt={5}>
            <Text fontSize="sm" fontWeight="medium">Summary</Text>
            {summary ? this.renderSummary(summary) : this.renderSkeleton(3)}
          </Box>
        </Stack>
        
      </Box>
    );
  }
}

export default withHook(FeedItem);