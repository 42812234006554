import React from "react";
import { Box, Flex, Text, Button, Image, Stack } from "@chakra-ui/core";
import { useHistory, Link } from 'react-router-dom';
import { BsFillPersonFill } from 'react-icons/bs';

const Header = ({ signOut, isAuthenticated, ...rest }) => {
  const [show, setShow] = React.useState(false);
  const history = useHistory();

  function onMyProfile(e) {
    e.preventDefault();
    history.push("/profile");
  }

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      h="66px"
      color="grey.300"
      borderBottom="1px"
      borderColor="gray.300"
      boxShadow="0 1px 1px 0 rgba(0,0,0,0.05)"
      backgroundColor="white"
      {...rest}
    >
      <Flex align="center" ml={[0,0,'15%']}>
        <Link to="/home"><Image src="https://assets.projectcongress.com/logo.png" w="150px" /></Link>
      </Flex>

      {/*<Box display={{ sm: "block", md: "none" }} onClick={handleToggle}>
        <svg
          fill="white"
          width="12px"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
  </Box>*/}

      {/*<Box
        display={{ sm: show ? "block" : "none", md: "flex" }}
        width={{ sm: "full", md: "auto" }}
        alignItems="center"
        flexGrow={1}
      >
        <MenuItems>Docs</MenuItems>
        <MenuItems>Examples</MenuItems>
        <MenuItems>Blog</MenuItems>
      </Box>*/}

      {isAuthenticated && <Box
        display={{ sm: show ? "block" : "none", md: "block" }}
        mr={[2,2,'15%']}
      >
        <Button onClick={onMyProfile} bg="transparent" fontSize="sm" fontWeight="medium" border="1px" borderRadius="2px">
          <Stack isInline><Box as={BsFillPersonFill} /><Text>Profile</Text></Stack>
        </Button>
      </Box>}
    </Flex>
  );
};

export default Header;