import React, { useState, useEffect } from 'react';
import '../App.css';
import { 
  Image,
  Box,
  Flex,
  useToast } from "@chakra-ui/core";
import Name from './Name';
import Email from './Email';
import CreateProfile from './CreateProfile';
import Password from './Password';
import Zip from './Zip';
import Interests from './Interests';
import Address from './Address';

import actions from '../actions';
import { Route, Switch, useRouteMatch, useHistory, useLocation, Redirect } from 'react-router-dom';

function Signup({ login, onAuthStateChange, ...rest }) {
  const [form, updateForm] = useState({});
  const [interests, setInterests] = useState([]);

  let match = useRouteMatch();
  let history = useHistory();
  let { pathname } = useLocation();
  const toast = useToast();

  useEffect(() => {
    if(pathname === "/signup/interests" && interests.length === 0) fetchInterests();
  }, []);

  function showErrorToast(message) {
    toast({
        title: "Woops!",
        description: message,
        status: "error",
        duration: 5000,
        isClosable: true
      })
  }

  function onSelection(e) {
    const key = e.target.id;

    const selectedInterest = interests.filter(item => item.id === key).pop();
    if(!selectedInterest.disabled) {
        selectedInterest.selected = !selectedInterest.selected;

        const allSelectedInterests = interests.filter(item => item.selected);
        if(allSelectedInterests.length === 5) {
            for(const interest of interests) {
                if(!interest.selected) interest.disabled = true;
            }
        } else if(allSelectedInterests.length < 5) {
            for(const interest of interests) interest.disabled = false;
        }
        setInterests([...interests]);
    }
    
  }

  async function fetchInterests() {
    const { res = {}, err } = await actions.fetchInterests();
    if(err) showErrorToast(err.message);
    else {
        const { interests: items } = res;
        setInterests(items);
    } 
  }

  async function onNext(data = {}) {
    for(const key in data) {
        form[key] = data[key];
        updateForm(form);
    }
    const { name, email, password, zip } = form;

    switch(pathname) {
        /*case "/signup/name": history.push("/signup/email"); break;
        case "/signup/email": {
            const { err } = await actions.validateEmail(email);
            if(err) showErrorToast(err.message);
            else history.push("/signup/password");
        } break;
        case "/signup/password": {
            const { err } = await actions.createUser(name, email, password);
            onAuthStateChange();
            if(err) showErrorToast(err.message);
            else history.push("/signup/zip");
        } break;*/
        case "/signup/profile": {
            const { err } = await actions.createUser(name, email, password);
            onAuthStateChange();
            if(err) showErrorToast(err.message);
            else history.push("/signup/zip");
        } break;
        case "/signup/zip": {
            const { res = {}, err } = await actions.createLegislators(zip);
            if(err) showErrorToast(err.message);
            else {
                var nextStep;
                const { step } = res;
                if(step === "address") nextStep = "address";
                else {
                    nextStep = "interests"
                    await fetchInterests();
                }
                history.push(nextStep); 
            }
        } break;
        case "/signup/address": {
            const { res = {}, err } = await actions.createLegislators(zip);
            if(err) showErrorToast(err.message);
            else {
                await fetchInterests();
                history.push("/signup/interests");
            }
        } break;
        case "/signup/interests": {
            const selectedInterests = interests.filter(item => item.selected).map(item => item.id);
            const { res = {}, err } = await actions.createInterests(selectedInterests);
            if(err) showErrorToast(err.message);
            else history.push("/home");
        } break;
    }
  }

  return (
      <Flex justify="center">
        <Box p={[3,3,10]} w={['100%',null,'50%']} borderWidth="1px" backgroundColor="white" maxWidth="500px">
            {/*<Image src="https://assets.projectcongress.com/logo.png" w="10em" />*/}
            <Switch>
                {/*<Route path={`${match.path}/name`} render={(props) => <Name onNext={onNext} {...props} />} />                
                <Route path={`${match.path}/email`} render={(props) => <Email onNext={onNext} {...props} />} />
                <Route path={`${match.path}/password`} render={(props) => <Password onNext={onNext} {...props} />} />*/}
                <Route path={`${match.path}/profile`} render={(props) => <CreateProfile onNext={onNext} {...props} />} />
                <Route path={`${match.path}/zip`} render={(props) => <Zip onNext={onNext} {...props} />} />
                <Route path={`${match.path}/address`} render={(props) => <Address onNext={onNext} {...props} />} />
                <Route path={`${match.path}/interests`} render={(props) => <Interests interests={interests} onSelection={onSelection} onNext={onNext} {...props} />} />
                <Redirect to={`/signup/profile`} />
            </Switch>
        </Box>
      </Flex>
        
    
  );
}

export default Signup;
