import React from 'react';
import { useForm } from "react-hook-form";
import '../App.css';
import {
  Button,
  Text,
  Stack,
  Icon,
  Tag,
  Flex,
  Box } from "@chakra-ui/core";

function Interests({ onNext, onSelection, interests = [], mode, ...rest }) {
  const { handleSubmit, formState } = useForm();

  function Interest({ item = {}, ...rest }) {
    return (
      <Box style={{ cursor: item.disabled ? "auto" : "pointer" }} id={item.id} onClick={onSelection} key={item.id} p={5} m={2} borderWidth="1px" borderColor={item.selected ? "purple.500" : "inherit" } rounded="lg" flex="1" {...rest}>
        <Stack id={item.id} isInline justify="space-between" align="center">
          <Text id={item.id} color={item.disabled ? "gray.500" : "black" } fontSize="md" fontWeight={item.selected ? "bold" : "semibold"} textAlign="left">{item.name}</Text>
          <Icon id={item.id} d={item.selected ? "inline" : "none"} color="purple.500" name="check"></Icon>
        </Stack>
      </Box>
    );
  }

  function renderInterestTag(interest) {
    return <Tag key={interest.name} mr={2} mt={2} fontWeight="bold"
    textTransform="uppercase"
    fontSize="0.6em"
    letterSpacing="wide"
    color="purple.700">{interest.name}</Tag>;
  }

  return (
    <Box m={0} pt={5} pb={5} w="100%" {...rest} textAlign="center">
      <Text fontSize="lg" fontWeight="bold">SELECT UP TO 5 INTERESTS</Text>
      <Text fontSize="sm" fontWeight="normal" color="gray.500">We will use your interests to find relevant bills for you</Text>
      <Box className="pc_interests" mt={5} w="100%" overflow="scroll" borderWidth="1px" maxH="400px">
        {interests.map(item => <Interest key={item.id} item={item} />)}
      </Box>
      <Flex wrap="wrap">{interests.filter(item => item.selected).map(renderInterestTag)}</Flex>
      <Button
          mt={10}
          variantColor="purple"
          isLoading={formState.isSubmitting}
          type="submit"
          h="50px"
          borderRadius="2px"
          w="100%"
          fontSize="lg"
          fontWeight="bold"
          style={{textShadow:"0 -1px 0 rgba(0, 0, 0, .35)"}}
          onClick={handleSubmit(onNext)}
        >
            {mode === "edit" ? "Update" : "Next"}
        </Button>
    </Box>
  );
}

export default Interests;
