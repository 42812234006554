import React, { useState, useEffect } from 'react';
import './App.css';
import { ThemeProvider, CSSReset, theme, useToast, Text, Stack, Box, Flex } from "@chakra-ui/core";
import Header from './Header';
import Feed from './Feed';
import Login from './Login';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword';
import Profile from './Profile';
import LandingPage from './Landing/LandingPage';
import LandingHeader from './Landing/Header';
import TOS from './Landing/TOS';
import Privacy from './Landing/Privacy';

import actions from './actions';
import { Route, Switch, Redirect, Link, useLocation } from 'react-router-dom';

function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    document.body.style.backgroundColor = (location.pathname === "/home" || location.pathname === "/profile") ? "#F6F6F6" : "#FFFFFF";
  }, [location]);
}

function App() {
  usePageViews();
  const [isAuthenticated, setAuthState] = useState(true);
  const [loading, setLoading] = useState(false);
  const [headerState, setHeaderState] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setAuthState(actions.isAuthenticated());
    setLoading(false);
  }, []);

  function signOut() {
    actions.signOut();
    setAuthState("");
  }

  function onAuthStateChange() {
    const isAuthenticated = actions.isAuthenticated();
    setAuthState(isAuthenticated);
  }

  function onHeaderStateChange() {
    setHeaderState(!headerState);
  }

  async function onLogin({ email, password }, cb) {
    if(!email || !password) return;
    const { err } = await actions.signIn(email, password);
    
    const isAuthenticated = actions.isAuthenticated();
    setAuthState(isAuthenticated);

    if(cb) cb(err);
  }

  const customTheme = {
    ...theme,
    fonts: {
      body: "Inter, sans-serif",
      heading: theme.fonts.heading,
      mono: theme.fonts.mono
    },
    fontWeights: {
      normal: 400,
      medium: 600,
      bold: 900
    }
  }

  return (
    <ThemeProvider theme = {customTheme}>
      <CSSReset />
      <div className="App">
          {isAuthenticated ? (
            <div>
              {location.pathname.indexOf("/signup") === 0 ? <LandingHeader /> : <Header isAuthenticated={isAuthenticated} />}
              <Switch>
                <Route path="/home" render={(props) => <Feed onHeaderStateChange={onHeaderStateChange} {...props}/>} />
                <Route exact path="/profile" render={(props) => <Profile signOut={signOut} {...props}/>} />
                <Route path="/signup" render={(props) => <Signup onAuthStateChange={onAuthStateChange} {...props}/>} />
                <Route path="/tos" render={(props) => <TOS {...props}/>} />
                <Route path="/privacy" render={(props) => <Privacy {...props}/>} />
                <Redirect exact to="/home" />
              </Switch>
            </div>
          ) : (
            <div>
              <LandingHeader />
              <Switch>
                <Route exact path="/" render={(props) => <LandingPage onLogin={onLogin} {...props}/>} />
                <Route exact path="/login" render={(props) => <Login onLogin={onLogin} {...props}/>} />
                <Route path="/signup" render={(props) => <Signup onAuthStateChange={onAuthStateChange} {...props}/>} />
                <Route path="/forgot" render={(props) => <ForgotPassword onAuthStateChange={onAuthStateChange} {...props}/>} />
                <Route path="/tos" render={(props) => <TOS {...props}/>} />
                <Route path="/privacy" render={(props) => <Privacy {...props}/>} />
                <Redirect exact to="/" />
              </Switch>
            </div>
            
          )}

          {location.pathname.indexOf("/home") !== 0 &&
          <footer>
            <Box pt={"70px"} p={3} mb={10}  h={"70px"}>
              <Stack textAlign="center">
                <Stack spacing={10} isInline justify="center">
                  <Text fontWeight="medium" fontSize="sm"><Link to="/tos">Terms Of Service</Link></Text>
                  <Text fontWeight="medium" fontSize="sm"><Link to="/privacy">Privacy Policy</Link></Text>
                </Stack>
                <Text color="gray.500" fontWeight="normal" fontSize="sm">© 2020 <a href="https://barrickapps.com?ref=pc" rel="noopener noreferrer" target="_blank">Barrick Apps LLC</a></Text>
              </Stack>
            </Box>
          </footer>}
      </div>
    </ThemeProvider>
  );
}

export default App;
