import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import '../App.css';
import { FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Text,
  Box } from "@chakra-ui/core";

function Address({ onNext, mode, ...rest }) {
  const { handleSubmit, errors, register, formState } = useForm();

  function validateZip(value) {
    let error;
    if (!value) {
      error = "Zip is required";
    }
    return error || true;
  }

  function renderInput(props) {
    return (
      <Input
            name="zip"
            placeholder="SA"
            autoFocus={true}
            borderRadius="2px"
            height="70px"
            textAlign="center"
            fontSize="lg"
            ref={register({ validate: validateZip })}
            {...props}
          />
    )
  }

  function renderSuggestions(active, suggestions, onSelectSuggestion) {
    return (
      <Box className="suggestions-container">
        {
          suggestions.map((suggestion) => (
            <Box id={suggestion.description} key={suggestion.description} p={5} mt={1} borderWidth="1px" style={{ cursor:"pointer" }}
            className="suggestion"
              onClick={(event) => onSelectSuggestion(suggestion, event)}
            >
              {suggestion.description}
            </Box>
          ))
        }
      </Box>
    )
  }

  return (
    <Box m={0} pt={10} pb={mode === "edit" ? 5 : 10} {...rest} textAlign="center">
      <Text fontSize="lg" fontWeight="bold">WHAT IS YOUR STREET ADDRESS?</Text>
      <form onSubmit={handleSubmit(onNext)}>
        <FormControl mt={10} isInvalid={errors.zip}>
          <GooglePlacesAutocomplete
            apiKey={"AIzaSyDofqfmrY8wmDDAp-luvNihzCrK2dbCGWo"}
            renderInput={renderInput}
            renderSuggestions={renderSuggestions}
            placeholder="Street Address"
          />
          <FormErrorMessage>
            {errors.zip && errors.zip.message}
          </FormErrorMessage>
          <Text mt={1} fontSize="xs" color="gray.500">There are multiple representatives that work for your ZIP code. We will use your street address to find your representative.</Text>
        </FormControl>
        <Button
          mt={10}
          variantColor="purple"
          isLoading={formState.isSubmitting}
          type="submit"
          h="50px"
          borderRadius="2px"
          w="100%"
          fontSize="lg"
          fontWeight="bold"
          onClick={handleSubmit(onNext)}
        >
            {mode === "edit" ? "Update" : "Next"}
        </Button>
      </form>
    </Box>
  );
}

export default Address;
