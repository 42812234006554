import React from 'react';
import { useForm } from "react-hook-form";
import './App.css';
import { FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Flex,
  Image,
  Text,
  Box, 
  useToast} from "@chakra-ui/core";
import { useHistory, Link } from 'react-router-dom';
import actions from './actions';

function Login({ onLogin, ...rest }) {
  const { handleSubmit, errors, register, formState } = useForm();
  const history = useHistory();
  const toast = useToast();

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Email is required";
    }
    return error || true;
  }

  function validatePassword(value) {
    let error;
    if (!value) {
      error = "Password is required";
    }
    return error || true;
  }

  function signUp(e) {
    e.preventDefault();
    history.push("/signup");
  }

  function onLoginErr(err) {
    if(err) actions.showErrorToast(toast, err.message);
  }

  function onForgotPassword(e) {
    e.preventDefault();
    history.push("/forgot");
  }

  return (
    <Box p={[3,3,10]} m={{sm: 0, md:"3em auto 0 auto"}} borderWidth="1px" backgroundColor="white" maxW="500px" textAlign="center">
      {/*<Image src="https://assets.projectcongress.com/logo.png" w="10em" />*/}
      <form style={{width:"100%"}} onSubmit={handleSubmit((data) => onLogin(data, onLoginErr))}>
        <FormControl mt={5} isInvalid={errors.email}>
          <Text fontWeight="bold">Email</Text>
          <Input
            name="email"
            placeholder="Email"
            borderRadius="2px"
            h="70px"
            fontSize="lg"
            textAlign="center"
            ref={register({ validate: validateEmail })}
          />
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl mt={5} isInvalid={errors.password}>
          <Text fontWeight="bold">Password</Text>
          <Input
            name="password"
            placeholder="Password"
            type="password"
            textAlign="center"
            borderRadius="2px"
            h="70px"
            ref={register({ validate: validatePassword })}
          />
          <FormErrorMessage>
            {errors.password && errors.password.message}
          </FormErrorMessage>
        </FormControl>
        <Text mt={2} fontSize="xs" textAlign="right">Forgot your password? <Link style={{textDecoration:"underline"}} to="/forgot">Reset</Link></Text>
        <Button
          mt={4}
          backgroundColor="green.500"
          color="white"
          isLoading={formState.isSubmitting}
          type="submit"
          onClick={handleSubmit((data) => onLogin(data, onLoginErr))}
          w="100%"
          fontSize="lg"
          fontWeight="bold"
          borderRadius="2px"
          h="50px"
          style={{textShadow:"0 -1px 0 rgba(0, 0, 0, .35)"}}
          >
          Log In
        </Button>
        
      </form>

      <Box h="1px" w="75%" m={5} backgroundColor="gray.200" />
      <Text fontSize="sm">Don't have a profile?</Text>
      <Button
        backgroundColor="purple.500"
        color="white"
        mt={2}
        isLoading={formState.isSubmitting}
        type="submit"
        onClick={signUp}
        w="100%"
        borderRadius="2px"
        h="50px"
        fontSize="lg"
        fontWeight="bold"
        style={{textShadow:"0 -1px 0 rgba(0, 0, 0, .35)"}}
      >
        Create Profile
      </Button>
    </Box>
  );
}

export default Login;
