import React, { Component } from "react";
import { Box, Flex, Text, Button, Stack, Tag, Skeleton, FormControl, Input, FormErrorMessage, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, useDisclosure, Spinner, useToast, Divider } from "@chakra-ui/core";
import actions from './actions';
import Interests from './Signup/Interests';
import Zip from './Signup/Zip';
import Address from './Signup/Address';

function withHook(Component) {
  return function WrappedComponent(props) {
    const toast = useToast();
    const disclosure = useDisclosure();
    return <Component {...props} toast={toast} disclosure={disclosure} errors={{}}/>;
  }
}

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = { profile: {}, updatingProfile: false };
  }

  componentWillMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true });

    const { res = {}, err } = await actions.fetchProfile();
    const { profile = {} } = res;
    if(err) actions.showErrorToast(this.props.toast, err.message);
    else if(profile) {
      this.setState({ profile });
    }

    this.setState({ loading: false });
  }

  renderSkeleton = (length = 0) => {
    return (
      <div>
        {[...Array(length)].map((_,i) => <Skeleton key={i} h="30px" my="10px" m={5}/>)}
      </div>
    )
  }

  validateEmail = (value) => {
    let error;
    if (!value) {
      error = "Email is required";
    }
    return error || true;
  }

  renderInterest = (item) => {
    return <Tag key={item} mr={2} mt={2} fontWeight="bold"
    textTransform="uppercase"
    fontSize="0.6em"
    letterSpacing="wide"
    color="purple.700">{item}</Tag>;
  }

  renderEmail = (item) => {
    return <Tag key={item} mr={2} mt={2} fontWeight="bold"
    fontSize="xs"
    letterSpacing="wide"
    color="purple.700">{item}</Tag>;
  }

  onInterestSelection = (e) => {
    const key = e.target.id;
    const { interests = [] } = this.state;

    const selectedInterest = interests.filter(item => item.id === key).pop();
    if(!selectedInterest.disabled) {
        selectedInterest.selected = !selectedInterest.selected;

        const allSelectedInterests = interests.filter(item => item.selected);
        if(allSelectedInterests.length >= 5) {
            for(const interest of interests) {
                if(!interest.selected) interest.disabled = true;
            }
        } else if(allSelectedInterests.length < 5) {
            for(const interest of interests) interest.disabled = false;
        }
        this.setState({ interests });
      }
  }

  fetchInterests = async () => {
    this.setState({ loadingInterests: true });
    const { res = {}, err } = await actions.fetchInterests();
    if(err) {
      actions.showErrorToast(this.props.toast, err.message);
      this.onModalClose();
    } else {
        const { interests = [] } = res;
        if(interests.filter(item => item.selected).length >= 5) {
          for(const interest of interests) {
              if(!interest.selected) interest.disabled = true;
          }
      }
        this.setState({ interests });
    }
    this.setState({ loadingInterests: false });
  }

  updateProfileInterests = async () => {
    this.setState({ updatingProfile: true });
    const { interests = [] } = this.state;
    const selectedInterests = interests.filter(item => item.selected).map(item => item.id);
    const { res = {}, err } = await actions.updateProfile({ interests: selectedInterests });
    const { profile = {} } = res;

    if(err) actions.showErrorToast(this.props.toast, err.message);
    else if(profile) {
      this.setState({ profile });
    }

    this.setState({ updatingProfile: false }, this.onModalClose);
  }

  handleOpen = (mode) => {
    const { disclosure } = this.props;
    const { onOpen } = disclosure;
    this.setState({ modalMode: mode }, onOpen);
    if(mode === "interests") this.fetchInterests();
  }

  updateLocation = async ({ zip, address }) => {
    this.setState({ updatingProfile: true });
    const { res = {}, err } = await actions.createLegislators(zip || address);
    if(err) {
      this.onModalClose();
      actions.showErrorToast(this.props.toast, err.message);
    } else {
      const { step, profile } = res;
      if(step === "address") {
        this.props.disclosure.onOpen();
        this.setState({ modalMode: "address" });
      } else {
        if(profile) this.setState({ profile });
        this.onModalClose();
      }
    }
    this.setState({ updatingProfile: false });
  }

  onProfileUpdate = async (data) => {
    const { modalMode } = this.state;
    if(modalMode === "interests") await this.updateProfileInterests(data);
    if(modalMode === "zip" || modalMode === "address") await this.updateLocation(data);
  }

  renderModalBody = () => {
    const { updatingProfile, loadingInterests, modalMode, interests } = this.state;
    if(modalMode === "interests" && loadingInterests) return this.renderSpinner("Loading Interests...");
    else if(modalMode === "interests" && !loadingInterests && !updatingProfile) return <Interests onSelection={this.onInterestSelection} onNext={this.onProfileUpdate} interests={interests} mode={"edit"} />;
    else if(modalMode === "zip" && !updatingProfile) return <Zip onNext={this.onProfileUpdate} mode={"edit"} />
    else if(modalMode === "address" && !updatingProfile) return <Address onNext={this.onProfileUpdate} mode={"edit"} />
    else if(updatingProfile) return this.renderSpinner("Updating Profile...");
  }

  onModalClose = (e) => {
    const { updatingProfile } = this.state;
    if(!updatingProfile) this.setState({ modalMode: "" });
  }

  editInterestsModal = () => {
    const { updatingProfile, modalMode = "" } = this.state;
    const { disclosure } = this.props;
    const { isOpen, onClose } = disclosure;

    return (  
      <Modal isOpen={isOpen || updatingProfile} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {this.renderModalBody()}
            <Stack>
              <Button 
                variantColor="gray" 
                h="50px"
                borderRadius="2px"
                w="100%"
                fontSize="lg"
                fontWeight="bold"
                isLoading={updatingProfile}
                onClick={onClose}>
                Cancel
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  renderSpinner = (message) => {
    return (
      <Stack mt={message ? 10 : 5} mb={message ? 10 : 5} align="center">
        <Spinner />
        <Text fontWeight="bold">{message}</Text>
      </Stack>
    )
  }

  /*<FormControl isInvalid={this.props.errors.email}>
                  <Input
                    name="email"
                    placeholder="Email"
                    value={email}
                  />
                  <FormErrorMessage>
                    {this.props.errors.email && this.props.errors.email.message}
                  </FormErrorMessage>
                </FormControl>*/

  render() {
    const { loading, profile } = this.state;
    const { interests: profileInterests = [], location, email } = profile;
    return (
      <Box p={5} w={['100%','100%','50%']} ml={[0,0,'25%']}>
          {this.editInterestsModal()}
          <Text d={["none","none","inherit"]} mt={3} fontSize="md" fontWeight="medium">Your Profile</Text>
          <Stack >
            <Box backgroundColor="white" borderWidth="1px" rounded="lg" borderRadius="2px" boxShadow="0 1px 1px 0 rgba(0,0,0,0.05)">
              <Stack >
                <Box p={5}>
                  <Text fontWeight="bold">Email</Text>
                  { loading ? this.renderSkeleton(1) : this.renderEmail(email) }
                </Box>

                <Divider />

                <Box p={5}>
                  <Text fontWeight="bold">Interests</Text>
                  {loading ? this.renderSkeleton(2) : 
                  <Flex wrap="wrap" alignContent="space-around">
                    {profileInterests.map(this.renderInterest)}
                  </Flex>}

                  {!loading && <Button mt={5}
                  variantColor="purple"
                  h="50px"
                  borderRadius="2px"
                  w={['100%','100%','50%']}
                  fontSize="md"
                  fontWeight="bold" onClick={() => this.handleOpen("interests")}>Update Interests</Button>}
                </Box>

                <Divider />

                <Box p={5}>
                  <Text fontWeight="bold">Location</Text>
                  {loading ? this.renderSkeleton(1) :
                  <Flex>
                    {this.renderInterest(location)}
                  </Flex>}
                  {!loading && <Button mt={5} 
                  variantColor="purple"
                  h="50px"
                  borderRadius="2px"
                  w={['100%','100%','50%']}
                  fontSize="md"
                  fontWeight="bold" onClick={() => this.handleOpen("zip")}>Update Location</Button>}
                </Box>

                <Divider />

                <Box p={5}>
                <Button onClick={this.props.signOut} 
                variant="ghost"
                fontSize="md"
                fontWeight="medium"
                bg="gray.50"
                >Sign Out</Button>
              </Box>
              </Stack>
            </Box>
          </Stack>
          <Text d={["none","none","inherit"]} mt={3} fontSize="md" fontWeight="medium">About</Text>
          <Stack mt={[3, 3, 0]}>
            <Box backgroundColor="white" borderWidth="1px" rounded="lg" borderRadius="2px" boxShadow="0 1px 1px 0 rgba(0,0,0,0.05)">
              <Stack p={5}>
                  <Box>
                    <Text fontWeight="bold">Data Sources</Text>
                    <br/>
                    <Text mr={1} fontWeight="medium">{"Votes & Current Legislators"}</Text>
                    <Text><a style={{textDecoration:"underline"}} href="https://projects.propublica.org/api-docs/congress-api/" target="_blank" rel="noopener noreferrer">{' '} ProPublica Congress API</a></Text>
                    <Text fontSize="sm">Votes are updated every 30 minutes</Text>

                    <Text mt={5} mr={1} fontWeight="medium">Bill Data</Text>
                    <Text><a style={{textDecoration:"underline"}} href="https://github.com/DavidBarrick/congress-scraper" target="_blank" rel="noopener noreferrer">Congress Scraper via GPO</a></Text>
                    <Text fontSize="sm">Bills are updated 5 times per day</Text>

                    <Text mt={5} mr={1} fontWeight="medium">Legislator Portraits</Text>
                    <Text><a style={{textDecoration:"underline"}} href="https://github.com/unitedstates/images" target="_blank" rel="noopener noreferrer">Images of Congress</a></Text>

                    <Text mt={5} mr={1} fontWeight="medium">Bill Summaries</Text>
                    <Text><a style={{textDecoration:"underline"}} href="https://www.loc.gov/crsinfo/about/" target="_blank" rel="noopener noreferrer">CRS</a></Text>
                  </Box>

                  <Divider />

                  <Box mt={2}>
                    <Text fontWeight="bold">Who Built This?</Text>
                    <br/>
                    <Box p={5} backgroundColor="gray.50" borderRadius="4px">
                    <Text>Hi 👋</Text>
                    <Text>I'm David.</Text>
                    <Text>Building products that help people make sense of data is my passion. I hope with this project, relevancy will breed connection, and connection will lead to a more healthy and engaged democracy.</Text>
                    </Box>
                    <br/>

                    <Text fontSize="sm">Want to support the project?</Text>
                    <Text fontSize="sm">You can buy me a ☕️ <a style={{textDecoration:"underline"}} href="https://www.buymeacoffee.com/davbarrick" target="_blank" rel="noopener noreferrer">here</a></Text>
                    <Text mt={3} fontSize="sm">Have Feedback?</Text>
                    <Text fontSize="sm">I'd love to hear it. Shoot me an email <a style={{textDecoration:"underline"}} href="mailto:david@projectcongress.com" target="_blank" rel="noopener noreferrer">here</a></Text>
                  </Box>
              </Stack>
            </Box>

            
          </Stack>
      </Box>
    );
  }
};

export default withHook(Profile);