import React from 'react';
import '../App.css';
import { Text, Stack, Box, Flex, Button, SimpleGrid, Icon, Image, Divider } from "@chakra-ui/core";
import logo from './1.jpg';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <Stack backgroundColor="white" mb={5} spacing={[10, null, 10]}>
      <Flex justify="center">
        <SimpleGrid w={['100%',null,'80%']} spacing="10px" columns={[1, null, 2]}>
          <Flex p={10} alignItems="center">
            <Stack ml={[0, null, 10]} mr={[0, null, 10]} alignItems="center" textAlign="center" spacing={[5, 10]}>
              <Text fontSize="4xl" fontWeight="bold">Congress,<br />relevant to you.</Text>
              <Text fontSize="lg" fontWeight="normal" maxWidth="600px">Project Congress is a free tool to help you stay informed with what's happening in Congress based on your interests and location.</Text>
              <Link style={{textDecoration:"none", width:"100%", maxWidth:"300px"}} to="/signup/profile">
                <Button 
                  bg="purple.500"
                  height="60px"
                  color="white"
                  fontWeight="bold"
                  w="100%"
                  fontSize="xl">Get Started</Button>
              </Link>
              {/*<Flex 
                align="center"
                justify="center"
                borderColor="purple.500"
                borderWidth="1px"
                height="60px"
                w={['100%', null, '50%']}
                borderRadius="0.25rem">
                <Text fontSize="xl" color="purple.500" fontWeight="bold">Launching Soon 🇺🇸</Text>
              </Flex>*/}
            </Stack>
          </Flex>
          <Flex justify="center">
            <Image h="600px" src={logo}/>
          </Flex>
        </SimpleGrid>
        
      </Flex>
      <Flex justify="center">
        <Box w={['100%', null, '60%']} textAlign="center">
          <SimpleGrid columns={[1, null, 2]} spacing={["20px",null,"50px"]}>
            <Stack p={10} bg="purple.50" align="center" spacing={6} borderRadius="0.25rem">
              <Icon name="lock" />
              <Text fontSize="xl" fontWeight="bold">Privacy First</Text>
              <Text fontSize="md" fontWeight="noraml">We don't share or sell your personal information with any 3rd party.</Text>
            </Stack>
            <Stack p={10} bg="purple.50" align="center" spacing={6} borderRadius="0.25rem">
              <Icon name="info" />
              <Text fontSize="xl" fontWeight="bold">Personalized</Text>
              <Text fontSize="md" fontWeight="noraml">You'll only see relevant events in your feed based on your interests and location.</Text>
            </Stack>
            <Stack p={10} bg="purple.50" align="center" spacing={6} borderRadius="0.25rem">
              <Icon name="view-off" />
              <Text fontSize="xl" fontWeight="bold">No Ads</Text>
              <Text fontSize="md" fontWeight="noraml">Free. Forever.</Text>
            </Stack>
            <Stack p={10} bg="purple.50" align="center" spacing={6} borderRadius="0.25rem">
              <Icon name="not-allowed" />
              <Text fontSize="xl" fontWeight="bold">No Trackers</Text>
              <Text fontSize="md" fontWeight="noraml">We don't use trackers or cookies to follow you across the web. Our anonymous analytics are <a style={{textDecoration:"underline"}} href="https://github.com/plausible/analytics" rel="noopener noreferrer" target="_blank">open source.</a></Text>
            </Stack>
          </SimpleGrid>
        </Box>
      </Flex>
      <Divider />
      <Flex justify="center" align="center" flexDir="column">
        <Text mb={2} fontWeight="bold">Featured on Product Hunt! 🚀</Text>
        <a href={"https://www.producthunt.com/posts/project-congress?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-project-congress"} target="_blank">
          <img src={"https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=227850&theme=light"} alt={"Project Congress - A feed for congress based on your interests & location | Product Hunt Embed"} style={{width: "250px", height: "54px"}} width="250px" height="54px" />
        </a>
      </Flex>
    </Stack>
    
  );
}

export default Home;
