import axios from 'axios';
import { CognitoUser, CognitoUserPool, CognitoUserSession, CognitoIdToken, CognitoAccessToken, CognitoRefreshToken, AuthenticationDetails } from 'amazon-cognito-identity-js';

const API_KEY = process.env.REACT_APP_STAGE === "prod" ? process.env.REACT_APP_API_KEY : process.env.REACT_APP_API_KEY_DEV;
const API_ENDPOINT = process.env.REACT_APP_STAGE === "prod" ? process.env.REACT_APP_API_ENDPOINT : process.env.REACT_APP_API_ENDPOINT_DEV;
const WS_ENDPOINT = process.env.REACT_APP_WS_ENDPOINT;

async function fetchAuth() {
    const promise = new Promise(function(resolve, reject) {
        let cognitoUser = currentUser();
        if(cognitoUser) {
            cognitoUser.getSession(function(err, session) {
                if(err) reject(err);
                else resolve(session);
            });
        } else {
            reject({ status: "NotAuthorized" });
        }
    });

    return promise;    
}

async function createLegislators(address) {
    try {
        const { idToken } = await fetchAuth();
        const authToken = idToken.getJwtToken();

        const { data = {} } = await axios.post(`${API_ENDPOINT}/legislators`, { address }, { headers: { 'x-api-key' : API_KEY, Authorization: authToken } });
        const { body } = data;
        const res = JSON.parse(body);
        return { res };
    } catch(err) {
        const errData = err.response ? err.response.data : err;
        return { err: errData };
    }
}

async function fetchFeed(nextToken) {
    try {
        const { idToken } = await fetchAuth();
        const authToken = idToken.getJwtToken();
        const { data = {} } = await axios.get(`${API_ENDPOINT}/feed${nextToken ? `?nextToken=${nextToken}` : ""}`, { headers: { 'x-api-key' : API_KEY, Authorization: authToken } });
        const { body } = data;
        const res = JSON.parse(body);
        return { res };
    } catch(err) {
        const errData = err.response ? err.response.data : err;
        return { err: errData };
    }
}

async function fetchFeedItem(item_id) {
    //console.log("Fetch Feed Item: ", item_id);
    try {
        const { idToken } = await fetchAuth();
        const authToken = idToken.getJwtToken();

        const { data = {} } = await axios.get(`${API_ENDPOINT}/feed/${item_id}`, { headers: { 'x-api-key' : API_KEY, Authorization: authToken } });
        const { body } = data;
        const res = JSON.parse(body);
        return { res };
    } catch(err) {
        const errData = err.response ? err.response.data : err;
        return { err: errData };
    }
}

async function fetchProfile() {
    //console.log("Fetch Profile");
    try {
        const { idToken } = await fetchAuth();
        const authToken = idToken.getJwtToken();

        const { data = {} } = await axios.get(`${API_ENDPOINT}/profile`, { headers: { 'x-api-key' : API_KEY, Authorization: authToken } });
        const { body } = data;
        const res = JSON.parse(body);
        return { res };
    } catch(err) {
        const errData = err.response ? err.response.data : err;
        return { err: errData };
    }
}

async function updateProfile(updates) {
    //console.log("Update Profile: ", updates);
    try {
        const { idToken } = await fetchAuth();
        const authToken = idToken.getJwtToken();

        const { data = {} } = await axios.put(`${API_ENDPOINT}/profile`, updates, { headers: { 'x-api-key' : API_KEY, Authorization: authToken } });
        const { body } = data;
        const res = JSON.parse(body);
        return { res };
    } catch(err) {
        const errData = err.response ? err.response.data : err;
        return { err: errData };
    }
}

async function fetchInterests() {
    try {
        const { idToken } = await fetchAuth();
        const authToken = idToken.getJwtToken();

        const { data = {} } = await axios.get(`${API_ENDPOINT}/interests`, { headers: { 'x-api-key' : API_KEY, Authorization: authToken } });
        const { body } = data;
        const res = JSON.parse(body);
        return { res };
    } catch(err) {
        const errData = err.response ? err.response.data : err;
        return { err: errData };
    }
}

async function createInterests(interests) {
    try {
        const { idToken } = await fetchAuth();
        const authToken = idToken.getJwtToken();

        const { data = {} } = await axios.post(`${API_ENDPOINT}/interests`, { interests }, { headers: { 'x-api-key' : API_KEY, Authorization: authToken } });
        const { body } = data;
        const res = JSON.parse(body);
        return { res };
    } catch(err) {
        const errData = err.response ? err.response.data : err;
        return { err: errData };
    }
}

async function validateEmail(email) {
    try {
        const { data } = await axios.post(`${API_ENDPOINT}/validate/email`, { email }, { headers: { 'x-api-key' : API_KEY } });
        return { res: data };
    } catch(err) {
        const errData = err.response ? err.response.data : err;
        return { err: errData };
    }
}

async function createUser(name, _email, password) {
    //console.log("Create User: ", _email);
    try {
        const { data = {} } = await axios.post(`${API_ENDPOINT}/user`, { name, email: _email, password }, { headers: { 'x-api-key' : API_KEY } });
        const { email, session } = data;

        let userPool = cognitoPool();
        let userData = {
            Username: email,
            Pool: userPool,
        };
        let cognitoUser = new CognitoUser(userData);
        let cognitoSession = buildCognitoSession(session);
        cognitoUser.setSignInUserSession(cognitoSession);
        
        return { res: {} };
    } catch(err) {
        const errData = err.response ? err.response.data : err;
        return { err: errData };
    }
}

function buildCognitoSession(authResult) {
    const idToken = new CognitoIdToken(authResult);
    const accessToken = new CognitoAccessToken(authResult);
    const refreshToken = new CognitoRefreshToken(authResult);

    const sessionData = {
        IdToken: idToken,
        AccessToken: accessToken,
        RefreshToken: refreshToken,
    };

    return new CognitoUserSession(sessionData);
}

async function signIn(email, password) {
    let authenticationData = {
        Username : email,
        Password : password,
    };
    let authenticationDetails = new AuthenticationDetails(authenticationData);

    let userPool = cognitoPool()
    let userData = {
        Username : email,
        Pool : userPool
    };
    let cognitoUser = new CognitoUser(userData);

    const promise = new Promise(function(resolve, reject) {
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function () {
                resolve({});
            },
    
            onFailure: function(err) {
                //console.log('authenticateUserError: ' + JSON.stringify(err));
                reject(err);
            },
            mfaRequired: function(codeDeliveryDetails) {
                //console.log('authenticateUser: ' + codeDeliveryDetails);
                resolve({});
            },
            newPasswordRequired: function(userAttributes, requiredAttributes) {
                //console.log('authenticateUser: ' + userAttributes + ' ' + requiredAttributes);
                resolve({});
            }
        });
    });

    try {
        await promise;
    } catch(err) {
        return { err } 
    }

    return {};
}

async function inputForgotPasswordCode(email, newPassword, code) {
    let userPool = cognitoPool();
    let userData = {
        Username : email,
        Pool : userPool
    };
    let cognitoUser = new CognitoUser(userData);

    const promise = new Promise(function(resolve, reject) {
        cognitoUser.confirmPassword(code, newPassword, {
            onSuccess() {
                //console.log('Password confirmed!');
                resolve();
            },
            onFailure(err) {
                //console.log('Password not confirmed!');
                reject(err);
            }
        });
    });

    try {
        await promise;
        return { }
    } catch(err) {
        return { err };
    }
    
}

async function forgotPassword(email) {
    let userPool = cognitoPool();
    let userData = {
        Username : email,
        Pool : userPool
    };
    let cognitoUser = new CognitoUser(userData);

    const promise = new Promise(function(resolve,reject) {
        cognitoUser.forgotPassword({
            onSuccess: function(data) {
                // successfully initiated reset password request
                //console.log('CodeDeliveryData from forgotPassword: ', data);
                resolve();
            },
            onFailure: function(err) {
                reject(err);
            }
        });
    });

    try {
        await promise;
        return { };
    } catch(err) {
        return { err };
    }
}

function signOut() {
    let cognitoUser = currentUser();
    if(cognitoUser) cognitoUser.signOut();
}

function isAuthenticated() {
    let cognitoUser = currentUser();
    if(cognitoUser) return true;

    return false;
}

function currentUser() {
    let userPool = cognitoPool();
    return userPool.getCurrentUser();
}

function cognitoPool() {
    let poolData = {
        UserPoolId: process.env.REACT_APP_COGNITO_POOL, // Your user pool id here
        ClientId: process.env.REACT_APP_COGNITO_CLIENT, // Your client id here
    };
    return new CognitoUserPool(poolData);
}

function initiateWebsocketConnection(token, callback) {
    const socket = new WebSocket(`${WS_ENDPOINT}?token=${token}`);

    // Listen for messages
    socket.addEventListener('message', function (event) {
        if(callback) callback(JSON.parse(event.data));
    });
}

function showErrorToast(toast, message, type = "error") {
    toast({
        title: type === "error" ? "Woops!" : "Success!",
        description: message,
        status: type,
        duration: 5000,
        isClosable: true,
    })
}

export default {
    createLegislators,
    validateEmail,
    createUser,
    fetchInterests,
    createInterests,
    fetchFeed,
    signIn,
    signOut,
    isAuthenticated,
    fetchProfile,
    showErrorToast,
    fetchFeedItem,
    initiateWebsocketConnection,
    updateProfile,
    forgotPassword,
    inputForgotPasswordCode
}