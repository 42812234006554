import React from 'react';
import { useForm } from "react-hook-form";
import '../App.css';
import { FormErrorMessage,
  FormControl,
  Input,
  Button,
  Text,
  Box } from "@chakra-ui/core";

function Name({ onNext, mode, ...rest }) {
  const { handleSubmit, errors, register, formState } = useForm();

  function validateZip(value) {
    let error;
    if (!value) {
      error = "Zip is required";
    }
    return error || true;
  }

  return (
    <Box m={0} pt={5} pb={5} w="100%" {...rest} textAlign="center">
    <Text fontSize="lg" fontWeight="bold">WHAT IS YOUR ZIP CODE?</Text>
      <form onSubmit={handleSubmit(onNext)}>
        <FormControl mt={10} isInvalid={errors.zip}>
          {/*<FormLabel htmlFor="name">Zip Code</FormLabel>*/}
          <Input
            name="zip"
            placeholder="Zip Code"
            autoFocus={true}
            borderRadius="2px"
            height="70px"
            textAlign="center"
            fontSize="lg"
            inputmode="numeric"
            pattern="[0-9]*"
            ref={register({ validate: validateZip })}
          />
          <FormErrorMessage>
            {errors.zip && errors.zip.message}
          </FormErrorMessage>
          <Text mt={1} fontSize="xs" color="gray.500">We will use your zip code to find your senators and representative</Text>
        </FormControl>
        <Button
          mt={10}
          variantColor="purple"
          isLoading={formState.isSubmitting}
          type="submit"
          h="50px"
          borderRadius="2px"
          w="100%"
          fontSize="lg"
          fontWeight="bold"
          style={{textShadow:"0 -1px 0 rgba(0, 0, 0, .35)"}}
          onClick={handleSubmit(onNext)}
        >
            {mode === "edit" ? "Update" : "Next"}
        </Button>
      </form>
    </Box>
  );
}

export default Name;
